import React, { useState } from "react";
import { calculateEndTime } from "../hooks/commonFunctions";
import Spinner from "./spinner";
import { getDateFormate } from "../hooks/commonFunctions";
import RateService from "./rateService";
import Model from "./model";

const MyBookingsData = ({
  deleteId,
  bookingData,
  activeBooking,
  loading,
  handleCancel,
  loadingCancel,
}) => {
  const [modalIsOpen, setIsOpenModal] = useState(false);
  const [rateBooking, setRateBooking] = useState();

  const openModal = (booking) => {
    setIsOpenModal(true);
    setRateBooking(booking);
  };

  const closeModal = () => {
    // fetchData();
    setIsOpenModal(false);
  };

  return (
    <>
      {!loading ? (
        <>
          {bookingData &&
            bookingData.length > 0 &&
            bookingData.map((booking) => {
              let endTime = calculateEndTime(booking.booking_time);
              return (
                <div className="text-black2">
                  <div
                    className={` ${
                      activeBooking === "cancelled"
                        ? "!border-red1 bg-red2"
                        : " border-grey9"
                    } border px-2.5 py-3 rounded`}
                  >
                    <div className="text-xs mb-2">
                      {getDateFormate(booking.day)}
                    </div>
                    <div className="block text-xs">
                      <div className="inline-block ">
                        {booking.booking_time + " - " + endTime}
                      </div>
                      {activeBooking === "upcoming" ? (
                        <div className="inline-block float-right">
                          Remind Me
                          <label class="switch ml-2 align-middle">
                            <input type="checkbox" checked />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="block">
                      <div className="mt-2 inline-block w-full">
                        <div className=" inline-block">
                          <div className="text-lg font-medium">
                            {booking.service_id.service_name}
                          </div>
                          {/* <div className="text-grey3 text-sm">
                            {booking.service_id.description}
                            5.4 mi
                          </div> */}
                          <div className="text-grey3 text-sm">
                            With{" "}
                            {booking.member_id.first_name +
                              " " +
                              booking.member_id.last_name}
                          </div>
                        </div>
                        <div className="float-right inline-block">
                          <div
                            className={` ${
                              activeBooking === "upcoming"
                                ? "text-green1"
                                : "text-grey3"
                            }  float-right text-xl font-bold `}
                          >
                            ${booking.orignal_price}
                          </div>
                          {activeBooking === "upcoming" ? (
                            <>
                              <div className="w-full inline-block">
                                {loadingCancel && deleteId === booking._id ? (
                                  <Spinner />
                                ) : (
                                  <button
                                    onClick={() => handleCancel(booking._id)}
                                    className=" border border-grey-9 rounded text-xs mt-1 px-2 py-1 float-right"
                                  >
                                    Cancel
                                  </button>
                                )}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {activeBooking === "upcoming" ? (
                        <div className="inline-block float-right mt-[-8px]">
                          <a
                            className="text-green1 inline-block text-xs font-bold "
                            href="pdf/cancellation_policy.pdf"
                            download
                          >
                            Cancellation Policy
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {activeBooking === "completed" ? (
                      <div className="text-green1 font-bold  block mt-3 text-xs">
                        <button
                          onClick={() => openModal(booking)}
                          className=" underline"
                        >
                          Rate our Service
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    {booking.service_id.hashtag ? (
                      <div className="block mt-2 text-xs">
                        {booking.service_id.hashtag.map((hashtag) => {
                          return (
                            <div className="inline-block border border-grey9 p-1 mr-1 mt-1 rounded bg-grey10">
                              {hashtag}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}

          {bookingData && bookingData.length === 0 ? (
            <div className="text-center col-span-2">No Bookings available</div>
          ) : (
            ""
          )}

          <Model
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
            content={
              <RateService closeModal={closeModal} rateBooking={rateBooking} />
            }
            dialogClassName="modal-width-profile"
          />
        </>
      ) : (
        <div className="col-span-2">
          <Spinner />
        </div>
      )}
    </>
  );
};

export default MyBookingsData;
