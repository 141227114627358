import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import "./HorizontalDatePicker.css"; // Add your custom CSS for horizontal layout if needed

const StarRating = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDatePicker = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="horizontal-date-picker">
      <div className="date-input" onClick={toggleDatePicker}>
        Select Date
      </div>
      {isOpen && (
        <DatePicker
          selected={null} // Pass your selected date state here
          onChange={(date) => console.log(date)} // Handle date change
          inline
          calendarClassName="horizontal-calendar"
          onClickOutside={toggleDatePicker}
        />
      )}
    </div>
  );
};

export default StarRating;
